import React, { Fragment, useState } from "react";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { API_USER } from "../../../redux/apiRoutes";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Form,
} from "reactstrap";

import { useSelector } from "react-redux";
import axios from "axios";
import { useMemo } from "react";
import FilterComponent from "../../common/filter";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';
import SweetAlert from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";

function Users(props) {
  const user = useSelector((content) => content.User);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    unregister,
    formState: { errors },
  } = useForm();

  const [modal, setModal] = useState(false);
  const [dataUsers, setDataUsers] = useState([]);

  const toggle = () => {setModal(!modal); reset(); setEditingUser(false); setPermissionsSelected([]);};

  const [getUsers, setGetUsers] = useState(false);
  const getUsersList = async () => {
    setGetUsers(true);

    await axios.get(`${API_USER}?status=ACTIVE,DISABLED`).then(response => {
      if(response.data.docs.length>0){
        setDataUsers(response.data.docs);
      }
      setGetUsers(false);
    }).catch(e => {
      setGetUsers(false);
      toast.error("No se pudo obtener el listado del Usuarios: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [isEditingUser, setEditingUser] = useState(false);
  const [userData, setuserData] = useState(false);
  const handleViewUser = (user) => {
    toggle();
    setEditingUser(true);
    setuserData(user);
    switch(user.role){
      case 'ADMIN':
        setPermissionsSelected(['ADMINISTRADOR GENERAL']);
        break;
      case 'GERENTE':
        setPermissionsSelected(['GERENTE']);
      break;
      case 'SUPERVISOR':
        setPermissionsSelected(['SUPERVISOR']);
      case 'CAPTURISTA':
        setPermissionsSelected(['CAPTURISTA']);
      break;
      case 'SISTEMAS':
        setPermissionsSelected(['SISTEMAS']);
      break;
      case 'ALMACEN':
        setPermissionsSelected(['ALMACEN']);
      break;
      case 'COTIZADOR':
        setPermissionsSelected(['COTIZADOR']);
      break;
    }
    setTimeout(()=>{
      setValue('name', user.name);
      setValue('email', user.email);
      register('password', {required:false});
      setValue('phone', user.phone);
      setValue('address', user.address);
      setValue('city', user.city);
      setValue('state', user.state);
      setValue('charge', user.charge);
    },500)
  }

  const handleStatusUser = (user,status) => {
    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Se ${status === 'DISABLED' ? 'desactivará' : 'activará'} el usuario: ${user.name}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        await axios.patch(`${API_USER}/${user.uuid}`,{status:status}).then(response => {
          getUsersList();
          SweetAlert.fire("¡Listo!", `usuario ${status === 'DISABLED' ? 'desactivado' : 'activado'}`, "success");
        }).catch(error => {
          setTimeout(() => {
            toast.error(
              "Error al eliminar productos: " + error
            );
          }, 200);
        });
      }
    });
  }

  const handleDeleteUser = (user) => {
    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Se eliminara el usuario: ${user.name}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        await axios.delete(`${API_USER}/${user.uuid}`).then(response => {
          getUsersList();
          SweetAlert.fire("¡Listo!", "usuario eliminado", "success");
        }).catch(error => {
          setTimeout(() => {
            toast.error(
              "Error al eliminar productos: " + error
            );
          }, 200);
        });
      }
    });
  }

  const columns = [
    {
      name: "Nombre",
      selector: (row) => <b>{row.name}</b>,
      sortable: true,
      center: true,
      width: '200px'
    },
    {
      name: "Puesto",
      selector: (row) => row.charge,
      center: true,
    },
    {
      name: "Teléfono",
      selector: (row) => row.phone,
      center: true,
    },
    {
      name: "Estado",
      selector: (row) => row.status === 'DISABLED' ? <span className="badge badge-warning">DESACTIVADO</span> : <span className="badge badge-success">ACTIVO</span>,
      center: true,
      sortable: true,
    },
    {
      name: "Creado",
      selector: (row) => new Date(row.createdAt).toLocaleDateString(),
      sortable: true,
      center: true,
    },
    {
      name: "Acciones",
      selector: (row) => <div style={{padding:5}}>
                          { user.role === 'GERENTE' && (row.role !== 'ADMIN' && row.role !== 'SISTEMAS' && row.role !== 'GERENTE') ?
                            <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}}onClick={()=>{handleViewUser(row)}}><i className="fa fa-pencil"></i></Button> : null }
                          { user.role === 'ADMIN' || user.role === 'SISTEMAS' ?
                          <>
                            <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}}onClick={()=>{handleViewUser(row)}}><i className="fa fa-pencil"></i></Button>
                            <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}}onClick={()=>{handleStatusUser(row, row.status === 'DISABLED' ? 'ACTIVE' : 'DISABLED')}}><i className="fa fa-power-off"></i></Button>
                            <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}}onClick={()=>{handleDeleteUser(row)}}><i className="fa fa-trash"></i></Button>
                          </>
                          : null }
                        </div>,
      center: true,
      width: '180px'
    },
  ];

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(
    false
  );

  const filteredItems = dataUsers.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const [creatingUser, setCreatingUser] = useState(false);
  const onSubmitCreateUser = async (user) => {
    setCreatingUser(true);

    if (user !== "") {
      user.uuid = uuidv4();
      user.status = 'ACTIVE';

      if(permissionsSelected.length===0){
        setCreatingUser(false);
        toast.error("Elige un Rol de Usuario", {position: 'bottom-right', style:{color:'white'}});
      }
      
      switch(permissionsSelected[0]){
        case 'ADMINISTRADOR GENERAL':
          user.role = 'ADMIN';
        break;
        case 'GERENTE':
          user.role = 'GERENTE';
        break;
        case 'CAPTURISTA':
          user.role = 'CAPTURISTA';
        break;
        case 'SUPERVISOR':
          user.role = 'SUPERVISOR';
        break;
        case 'SISTEMAS':
          user.role = 'SISTEMAS';
        break;
        case 'ALMACEN':
          user.role = 'ALMACEN';
        break;
        case 'COTIZADOR':
          user.role = 'COTIZADOR';
        break;
      }

      await axios.post(`${API_USER}`,user).then(response => {
        if(response.data){
          toggle();
          getUsersList();
          reset();
          toast.success("Usuario creado con éxito!", {position: 'bottom-right', style:{color:'white'}});
        }
        setCreatingUser(false);
      }).catch(e => {
        setCreatingUser(false);
        toast.error("No se pudo crear el Usuario: " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  };

  const [patchingUser, setPatchingUser] = useState(false);
  const onSubmitPatchUser = async (user) => {
    setPatchingUser(true);

    if (user !== "") {

      if(permissionsSelected.length===0){
        setPatchingUser(false);
        toast.error("Elige un Rol de Usuario", {position: 'bottom-right', style:{color:'white'}});
      }

      switch(permissionsSelected[0]){
        case 'ADMINISTRADOR GENERAL':
          user.role = 'ADMIN';
        break;
        case 'GERENTE':
          user.role = 'GERENTE';
        break;
        case 'CAPTURISTA':
          user.role = 'CAPTURISTA';
        break;
        case 'SUPERVISOR':
          user.role = 'SUPERVISOR';
        break;
        case 'SISTEMAS':
          user.role = 'SISTEMAS';
        break;
        case 'ALMACEN':
          user.role = 'ALMACEN';
        break;
        case 'COTIZADOR':
          user.role = 'COTIZADOR';
        break;
      }

      await axios.patch(`${API_USER}/${userData.uuid}`,user).then(response => {
        if(response.data){
          toggle();
          getUsersList();
          reset();
          toast.success("Usuario actualizado con éxito!", {position: 'bottom-right', style:{color:'white'}});
        }
        setPatchingUser(false);
      }).catch(e => {
        setPatchingUser(false);
        toast.error("No se pudo actualizar el Usuario: " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  };

  const [permissionsSelected,setPermissionsSelected] = useState([]);
  const permissions = [
    'ADMINISTRADOR GENERAL',
    'GERENTE',
    'SUPERVISOR',
    'CAPTURISTA',
    'SISTEMAS',
    'ALMACEN',
    'COTIZADOR',
  ];

  useEffect(() => {
    getUsersList();
    if(
      user.role !== 'ADMIN' 
      && user.role !== "SISTEMAS"
      && user.role !== "GERENTE"
    ){
      navigate('/inicio');
    }
  }, [navigate]);

  return (
    <Fragment>
      <Breadcrumb parent="Administrar" title="Usuarios" />
      
      <Container fluid={true}>
        <Row>
          <Col sm="12" style={{display:'flex', justifyContent:'flex-end', marginBottom: 20, paddingRight:30}}>
            <Button color="primary" onClick={toggle}>Crear Nuevo</Button>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="data-tables">
                {
                  getUsers ?
                    <div className="loader-box">
                      <div className="loader-1"></div>
                    </div>
                  :
                    <DataTable
                      columns={columns}
                      striped={true}
                      center={true}
                      data={filteredItems}
                      pagination
                      subHeader
                      subHeaderComponent={subHeaderComponent}
                      noDataComponent={<b style={{padding:10}}>No se encontro personal</b>}
                    />
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      
      <Modal isOpen={modal} toggle={toggle} size="lg" style={{minWidth:'50%'}}>
        <ModalHeader toggle={toggle}>{isEditingUser ? 'Editar Usuario' : 'Crear Nuevo Usuario' }
          <button className="btn-close invisible" type="button">
            <span aria-hidden="true" className="visible" onClick={toggle}></span>
          </button>
        </ModalHeader>
        <ModalBody>
          <Form className="needs-validation" noValidate="" onSubmit={handleSubmit( isEditingUser ? onSubmitPatchUser : onSubmitCreateUser)} style={{padding:20}}>
            <Row className="g-3 dflex mb-3">
              <Col md="6">
                <Label className="form-label font-weight-bold" for="validationCustom01">
                  Nombre
                </Label>
                <input className="form-control" id="validationCustom01" type="text" placeholder="Nombres" name="name" {...register("name", { required: true })} />
                <span>{errors.name && "Nombre(s) requerido"}</span>
              </Col>
              <Col md="6">
                <Label className="form-label font-weight-bold" for="validationCustom06">
                  Telefono
                </Label>
                <input className="form-control" id="validationCustom06" type="text" placeholder="Telefono" name="phone" {...register("phone", { required: true })} />
                <span>{errors.phone && "Telefono requerido"}</span>
              </Col>
            </Row>
            <Row className="g-3 dflex mb-3">
              <Col md="6">
                <Label className="form-label font-weight-bold" for="validationCustom03">
                  Email (Usuario)
                </Label>
                <input className="form-control" id="validationCustom03" type="email" placeholder="Usuario" name="email" {...register("email", { required: true })} />
                <span>{errors.email && "Usuario requerido"}</span>
              </Col>
              <Col md="6">
                <Label className="form-label font-weight-bold" for="validationCustom04">
                  Contraseña
                </Label>
                <input className="form-control" id="validationCustom04" type="text" placeholder="Contraseña" name="password" {...register("password")} />
                <span>{errors.password && "Contraseña requerido"}</span>
              </Col>
            </Row>
            <Row className="g-3 dflex mb-3">
              <Col md="4">
                <Label className="form-label font-weight-bold" for="validationCustom07">
                  Dirección
                </Label>
                <input className="form-control" id="validationCustom07" type="text" placeholder="Dirección" name="address" {...register("address")} />
              </Col>
              <Col md="4">
                <Label className="form-label font-weight-bold" for="validationCustom08">
                  Ciudad
                </Label>
                <input className="form-control" id="validationCustom08" type="text" placeholder="Ciudad" name="city" {...register("city")} />
              </Col>
              <Col md="4">
                <Label className="form-label font-weight-bold" for="validationCustom09">
                  Estado
                </Label>
                <input className="form-control" id="validationCustom09" type="text" placeholder="Estado" name="state" {...register("state")} />
              </Col>
            </Row>
            <Row className="g-3 dflex mb-3">
              <Col md="6">
                <Label className="form-label font-weight-bold" for="validationCustom10">
                  Puesto
                </Label>
                <input className="form-control" id="validationCustom10" type="text" placeholder="Puesto" name="charge" {...register("charge", {required:true})} />
              </Col>
              <Col md="6">
                <Label className="form-label font-weight-bold" for="validationCustom10">
                  Rol de Usuario
                </Label>
                <Typeahead
                  id="assignatureFinder"
                  clearButton
                  defaultSelected={permissionsSelected}
                  options={permissions}
                  placeholder="Elegir Permisos..."
                  onChange={(e)=>{setPermissionsSelected(e);}}
                />
              </Col>
            </Row>
            <Row style={{display:'flex', justifyContent:'end', paddingTop:20}}>
              {
                isEditingUser ? 
                  <Button disabled={patchingUser} className="btn btn-primary" style={{width:200}} color="primary">{"Guardar"}</Button> 
                : 
                  <Button disabled={creatingUser} className="btn btn-primary" style={{width:200}} color="primary">{"Crear"}</Button> 
              }
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
}

export default Users;
