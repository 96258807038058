import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Button, Card, CardBody, Col, Container, Form, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { API_CARDEX, API_USER, API_PRODUCT } from "../../../redux/apiRoutes";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';
import DatePicker from 'react-datepicker';
import es from "date-fns/locale/es";

import FilterComponent from "../../common/filter";
import SweetAlert from "sweetalert2";
import ScrollBar from "react-perfect-scrollbar";
import { getFormatDate, getFormatMoney } from "../../../utils/functions";

const Cardex = () => {
  const user = useSelector((content) => content.User);
  const navigate = useNavigate();
  
  const [modal, setModal] = useState(false);
  const toggle = () => {setModal(!modal);  reset(); setEditinCardex(false); clearModal(); setProducts([]);};

  const clearModal = () => {
    setProducts([]);
    setDisableEditionServices(false);
    setDisableEdition(false);
    setCardexType('IN');
    setDate(new Date());
    setEditDocument(true);
  }

  const [cardexType, setCardexType] = useState('IN');
  const handleChangeType = (value) => {
    setCardexType(value);
  }

  const [userSelected,setUserSelected] = useState([]);
  const [dataUsers,setdataUsers] = useState([]);
  const getUsersList = async () => {
    await axios.get(`${API_USER}?status=ACTIVE&role=CAPTURISTA,GERENTE_OPERACIONES`).then(response => {
      if(response.data.docs.length>0){
        const users = [];
        response.data.docs.map(user=>{
          const addUser = {
            uuid: user.uuid,
            name: user.name,
            cardexAmount: user.cardexAmount,
          }
          users.push(addUser);
        });
        setdataUsers(users);
      }
    }).catch(e => {
      toast.error("No se pudo obtener el listado de Usuarios: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [dataProducts,setdataProducts] = useState([]);
  const getProductsList = async () => {
    await axios.get(`${API_PRODUCT}?status=ACTIVE`).then(response => {
      if(response.data.docs.length>0){
        const products = [];
        response.data.docs.map(product=>{
          const addProduct = {
            uuid: product.uuid,
            name: product.serie ? product.serie : product.productModel ? product.productModel : '',
            location: '',
            qty: 0,
          }
          products.push(addProduct);
        });
        setdataProducts(products);
      }
    }).catch(e => {
      toast.error("No se pudo obtener el listado de Cirugias: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [dataCardexList, setDataCardexList] = useState([]);
  const [isGetCardexList, setIsGetCardexList] = useState(false);
  const [editPermissions, setEditPermissions] = useState(false);
  const [editDocument, setEditDocument] = useState(true);
  const getCardexList = async () => {
    setIsGetCardexList(true);

    let queryRule = '';
    if(user.role==='ADMIN' || user.role==='SISTEMAS' || user.role === "ALMACEN"){
      setEditPermissions(true);
      queryRule = `status=ACTIVE,DISABLED`;
    }

    if(user.role === "CAPTURISTA"){
      setEditPermissions(false);
      queryRule = `status=ACTIVE`;
    }
    
    await axios.get(`${API_CARDEX}?${queryRule}`).then(response => {
      if(response.data.docs.length>0){
        setDataCardexList(response.data.docs);
      }else{
        setDataCardexList([]);
      }
      setIsGetCardexList(false);
    }).catch(e => {
      setIsGetCardexList(false);
      toast.error("No se pudo obtener el listado de Movimientos: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const tipo = (status) =>{
    switch(status){
      case 'IN':
        return <div><span className="badge badge-success">Entrada</span></div>;
      case 'OUT':
        return <div><span className="badge badge-danger">Salida</span></div>;
      case 'SERVICE':
        return <div><span className="badge badge-secondary">Servicio</span></div>;
    }
  }

  const columns = [
    {
      name: "Documento / Referencia",
      selector: (row) => <b>{row.document}</b>,
      sortable: true,
      width: '220px',
      wrap: true,
    },
    {
      name: "Tipo",
      selector: (row) => <b>{tipo(row.type)}</b>,
      sortable: true,
    },
    {
      name: "Productos",
      selector: (row) => <b>{row.products.map((product)=>{return(<>{product.name}, </>)})}</b>,
      sortable: true,
      wrap: true,
      width: '270px'
    },
    {
      name: "Fecha",
      selector: (row) => row.date ? new Date(row.date).getDate() + '-' + (new Date(row.date).getMonth() + 1) + '-' + new Date(row.date).getFullYear() : '--',
      sortable: true,
    },
    {
      name: "Acciones",
      selector: (row) => <div style={{padding:5}}>
                            {editPermissions?
                            <>
                              <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}} onClick={()=>{handleEditAction(row)}}>{row.edit ? row.type === 'SERVICE' ? <i className="fa fa-eye"></i> : <i className="fa fa-pencil"></i> : <i className="fa fa-eye"></i>}</Button>
                              {/* <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}}onClick={()=>{handleDeleteAction(row)}}><i className="fa fa-trash"></i></Button> */}
                            </> 
                          : null}
                        </div>,
      width: '160px'
    },
  ];

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [date, setDate] = useState(new Date());
  const handleChangeStart = (date) => {
    setDate(date);
  };

  const [savingCardex, setSaveCardex] = useState(false);
  const saveCardex = async (data) => {
    setSaveCardex(true);
    if (data !== "") {
      if(date==="" || date === null){
        toast.error("Ingresa la fecha", {position: 'bottom-right', style:{color:'white'}});
        setSaveCardex(false);
        return;
      }
      data.date = date;

      if(products.length<1){
        toast.error('El movimiento no puede ser guardado sin productos', {position: 'bottom-right', style:{color:'white'}});
        setSaveCardex(false);
        return;
      }else{
        let productConfigured = true;
        let productWithError = '';
        products.map(product=>{
          if(product.qty<=0){
            productConfigured = false;
            productWithError = product.name;
          }
        });

        if(!productConfigured){
          toast.error("Define la cantidad para el producto: " + productWithError, {position: 'bottom-right', style:{color:'white'}});
          setSaveCardex(false);
          return;
        }
      }

      data.uuid = uuidv4();
      data.type = cardexType;
      data.products = products;
      data.edit = true;

      await axios.post(`${API_CARDEX}`,data).then(response => {
        if(response.data){
          toggle();
          getCardexList();
          reset();
          clearModal();
          toast.success("¡Movimiento creado con éxito!", {position: 'bottom-right', style:{color:'white'}});
        }
        setSaveCardex(false);
      }).catch(e => {
        setSaveCardex(false);
        toast.error("No se pudo crear el movimiento: " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  };

  const [disableEdition,setDisableEdition] = useState(false);
  const [disableEditionServices,setDisableEditionServices] = useState(false);

  const [currentCardex, setCurrentCardex] = useState({});
  const handleEditAction = (cardex) => {
    toggle();
    setEditinCardex(true);
    setCurrentCardex(cardex);

    setDisableEdition(true);

    setDisableEditionServices(true);

    setProducts(cardex.products);
    setCardexType(cardex.type);
    setEditDocument(cardex.edit);
    
    setTimeout(()=>{
      setValue('document', cardex.document);
      setDate(new Date(cardex.date));

      if(cardex.edit){
        cardex.products.map((product)=>{
          document.querySelector('#input-location-'+product.uuid).value = product.location;
          document.querySelector('#input-qty-'+product.uuid).value = product.qty;
        });
      }

    },500)
  }

  const [isEditingCardex, setEditinCardex] = useState(false);
  const [patchingCardex, setPatchingCardex] = useState(false);
  const patchCardex = async (data) => {
    setPatchingCardex(true);

    if (data !== "") {
      if(date==="" || date === null){
        toast.error("Ingresa la fecha de entrada", {position: 'bottom-right', style:{color:'white'}});
        setPatchingCardex(false);
        return;
      }
      data.date = date;

      if(products.length<1){
        toast.error('La entrada no puede ser guardada sin productos', {position: 'bottom-right', style:{color:'white'}});
        setPatchingCardex(false);
        return;
      }else{
        let productConfigured = true;
        let productWithError = '';
        products.map(product=>{
          if(product.qty<=0){
            productConfigured = false;
            productWithError = product.name;
          }
        });

        if(!productConfigured){
          toast.error("Define la cantidad para el producto: " + productWithError, {position: 'bottom-right', style:{color:'white'}});
          setPatchingCardex(false);
          return;
        }
      }

      data.products = products;

      await axios.patch(`${API_CARDEX}/${currentCardex.uuid}`,data).then(response => {
        if(response.data){
          getCardexList();
          toggle();
          setPatchingCardex(false);
          clearModal();
          toast.success("¡Movimiento actualizado con éxito!", {position: 'bottom-right', style:{color:'white'}});
        }
        setPatchingCardex(false);
      }).catch(e => {
        setPatchingCardex(false);
        toast.error("No se pudo actualizar el movimiento: " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  }

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(
    false
  );

  const handleDeleteAction = (data) => {
    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Se eliminará la movimiento: ${data.document}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        await axios.delete(`${API_CARDEX}/${data.uuid}`).then(response => {
          getCardexList();
          SweetAlert.fire("¡Listo!", "movimiento eliminado", "success");
        }).catch(error => {
          setTimeout(() => {
            toast.error(
              "Error al eliminar el movimiento: " + error
            );
          }, 200);
        });
      }
    });
  }

  const filteredItems = dataCardexList.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const [products, setProducts] = useState([]);
  const typeaheadRef = useRef(null);
  
  const handleProductSelected = (data) => {
    const currentProducts = [];
    if(data.length>0){
      typeaheadRef.current.clear();
      data[0].location = "";

      let existsProduct = false;
      let productName = '';
      products.map((product)=>{
        if(product.uuid===data[0].uuid){
          existsProduct = true;
          productName = product.name;
        }
      });

      if(!existsProduct){
        currentProducts.push(data[0]);
        setProducts(products.concat(currentProducts));
        document.querySelector("#scrolllist").scrollTop = 5000;
      }else{
        toast.warning('Ya agregaste el producto ' + productName,{style:{color:'white'}})
      }
    }
  }

  const handleProductEdited = (uuid,type,value) => {
    const modifiedProducts = [];

    products.map((product)=>{
      if(uuid===product.uuid){
        if(type==="qty"){
          product.qty = Number(value);
        }
        if(type==="location"){
          product.location = value;
        }
      }
      modifiedProducts.push(product);
    });
    setProducts(products);
  }

  const [isDeletingProduct, setIsDeletingProduct] = useState(false);
  const handleDeleteProduct = async (item) => {
    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Se eliminará el producto: ${item.name}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        setIsDeletingProduct(true);
        let modifiedProducts = [];
    
        products.map(product=>{
          if(product.uuid!==item.uuid){
            modifiedProducts.push(product);
          }
        });
        
        setProducts(modifiedProducts);
        setIsDeletingProduct(false);
      }
    });
  }

  useEffect(() => {
    if(
      user.role !== 'ADMIN' 
      && user.role !== "SISTEMAS"
      && user.role !== "GERENTE_OPERACIONES"
      && user.role !== "CAPTURISTA"
      && user.role !== "ALMACEN"
    ){
      navigate('/inicio');
    }
    getProductsList();
    getCardexList();
    getUsersList();
  }, [navigate]);

  return (
    <Fragment>
      <Breadcrumb parent="Almacen" title="Movimientos"/>
      <Container fluid={true}>
        <Row>
          <Col sm="12" style={{display:'flex', justifyContent:'flex-end', marginBottom: 20, paddingRight:30}}>
            <Button color="primary" onClick={toggle}>Nuevo Movimiento</Button>
          </Col>
        </Row>

        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="data-tables">
                <DataTable
                  columns={columns}
                  striped={true}
                  center={true}
                  data={filteredItems}
                  subHeader
                  pagination
                  subHeaderComponent={subHeaderComponent}
                  noDataComponent={<b style={{padding:10}}>No se encontraron movimientos</b>}
                  paginationRowsPerPageOptions={[50,100,200,500]}
                  paginationPerPage={50}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>

      <Modal isOpen={modal} toggle={toggle} size="lg" style={{minWidth:'55%'}}>
        <ModalHeader toggle={toggle}>{isEditingCardex ? 'Editar Movimiento' : 'Crear Nuevo Movimiento' }
          <button className="btn-close invisible" type="button">
            <span aria-hidden="true" className="visible" onClick={toggle}></span>
          </button>
        </ModalHeader>
        <ModalBody>
          <div className="container">
            {editDocument ?
                <>
                  <Form className="needs-validation" noValidate="" onSubmit={handleSubmit( isEditingCardex ? patchCardex : saveCardex)} style={{padding:20}}>
                    <Row className="g-3 dflex mb-3">
                      <Col md="12">
                        <Label className="form-label font-weight-bold" for="status">
                          Tipo de Movimiento
                        </Label>
                        <select disabled={disableEdition} defaultValue={cardexType} onChange={(e)=>handleChangeType(e.target.value)} className="form-control" name="type" >
                          <option value="IN">ENTRADA</option>
                          <option value="OUT">SALIDA</option>
                          {disableEditionServices ?
                          <option value="SERVICE">SALIDA POR SERVICIO</option> : null}
                        </select>
                      </Col>
                    </Row>
                    <Row className="g-3 dflex mb-3">
                      <Col md="12">
                        <Label className="form-label font-weight-bold" for="cardexNumber">
                          No. Nota / Factura / Referencia
                        </Label>
                        <input className="form-control" id="cardexNumber" type="text" placeholder="No. Documento" name="document" {...register("document", { required: true })} />
                        <span>{errors.document && "No. Documento Requerido"}</span>
                      </Col>
                    </Row>
                    <Row className="g-3 dflex mb-3">
                      <Col md="12">
                        <Label className="form-label font-weight-bold">
                          Fecha
                        </Label>
                        <DatePicker className="form-control digits"
                            selected={date}
                            onChange={handleChangeStart}
                            selectsStart
                            locale={es}
                            maxDate={new Date()}
                            dateFormat={'dd/MM/yyyy'}
                          />
                      </Col>
                    </Row>
                    { !disableEditionServices ?
                    <Row className="g-3 dflex mb-3">
                      <Col md="12">
                        <Label className="form-label font-weight-bold">
                          Productos
                          <br/>
                          {cardexType === "IN" ?
                            <span style={{color:'gray', fontSize:12}}>(Para agregar varias ubicaciones separalas con coma ",")</span>
                          : 
                            <span style={{color:'gray', fontSize:12}}>(Para eliminar una o más ubicaciones separalas con coma ",")</span>
                          }
                          
                        </Label>
                        <Typeahead
                          id="productS"
                          clearButton
                          labelKey="name"
                          options={dataProducts}
                          placeholder="Buscar productos..."
                          onChange={(e)=>{handleProductSelected(e)}}
                          ref={typeaheadRef}
                        />
                      </Col>
                    </Row> : null}

                    <ScrollBar id="scrolllist" className="scroll-vertical p-3"
                        options={{ suppressScrollX: true }}
                      >
                        <div></div>
                        {
                          products.length>0?
                            products.map((product,index)=>{
                              return(
                                <Row className="mb-2" key={index}>
                                  <Col md="12" style={{background:'#e4e4e4', padding:8, paddingTop:10, borderRadius:10, fontSize:'1rem'}}>
                                    <Row className="dflex">
                                      <Col sm="12" md="4" style={{padding:2, marginTop:10}}>
                                        <Label className="form-label">
                                          {product.name}
                                        </Label>
                                      </Col>
                                      <Col sm="12" md="4" style={{padding:2}}>
                                        <input disabled={disableEditionServices} id={`input-location-${product.uuid}`} className="form-control" type="text" style={{width:'90%'}} placeholder="Ubicaciónes" onChange={(e)=>handleProductEdited(product.uuid,'location',e.target.value)}/>
                                      </Col>
                                      <Col sm="12" md="4" style={{padding:2}}>
                                        <input disabled={disableEditionServices} id={`input-qty-${product.uuid}`}  className="form-control" style={{width:'80%'}} type="number" placeholder="Cantidad" onChange={(e)=>handleProductEdited(product.uuid,'qty',e.target.value)}/>
                                      </Col>
                                    </Row>
                                    {!disableEditionServices ?
                                    <div style={{position:'absolute', background: 'red', color: 'white', borderRadius:50, padding:5, textAlign: 'center', fontWeight:'bold', width:25, height:25, right:-3, zIndex:100, top:18, fontSize:11, cursor:'pointer'}} onClick={()=>handleDeleteProduct(product)}>X</div>: null}
                                  </Col>
                                </Row>
                              )
                            })
                          : null
                        }
                    </ScrollBar>
                    <Row style={{display:'flex', justifyContent:'end', paddingTop:20}}>
                    {
                      isEditingCardex ? 
                        currentCardex.type !== 'SERVICE' ?
                        <Button disabled={patchingCardex} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button> : null
                      : 
                        currentCardex.type !== 'SERVICE' ?
                        <Button disabled={savingCardex} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button> : null
                    }
                    </Row>
                  </Form>
                </>
              :
                <>
                  <h5 className="mb-3" style={{background:'#F9FF95', padding:8, borderRadius:10}}>Movimiento bloqueado por corte semanal</h5>
                    <Row className="g-3 dflex mb-3">
                      <Col md="12">
                        <Label className="form-label font-weight-bold" for="status">
                          Tipo de Movimiento
                        </Label>
                        {tipo(currentCardex.type)}
                      </Col>
                    </Row>
                    <Row className="g-3 dflex mb-3">
                      <Col md="12">
                        <Label className="form-label font-weight-bold" for="cardexNumber">
                          No. Nota / Factura / Referencia
                        </Label>
                        <h5>
                          {currentCardex.document}
                        </h5>
                      </Col>
                    </Row>
                    <Row className="g-3 dflex mb-3">
                      <Col md="12">
                        <Label className="form-label font-weight-bold">
                          Fecha
                        </Label>
                        <h5>
                          {getFormatDate(currentCardex.date)}
                        </h5>
                      </Col>
                    </Row>

                    <ScrollBar id="scrolllist" className="scroll-vertical p-3"
                        options={{ suppressScrollX: true }}
                      >
                        <div></div>
                        {
                          products.length>0?
                            products.map((product,index)=>{
                              return(
                                <Row className="mb-2" key={index}>
                                  <Col md="12" style={{background:'#e4e4e4', padding:8, paddingTop:10, borderRadius:10, fontSize:'1rem'}}>
                                    <Row className="dflex">
                                      <Col sm="12" md="4" style={{padding:2, marginTop:10}}>
                                        <Label className="form-label">
                                          {product.name}
                                        </Label>
                                      </Col>
                                      <Col sm="12" md="4" style={{padding:2}}>
                                        <span className="form-control">{product.location}</span>
                                      </Col>
                                      <Col sm="12" md="4" style={{padding:2}}>
                                        <span className="form-control" style={{width:'80%'}} type="number" placeholder="Cantidad">{product.qty}</span>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              )
                            })
                          : null
                        }
                    </ScrollBar>
                </>
            }
          </div>
        </ModalBody>
      </Modal>

    </Fragment>
  );
};

export default Cardex;