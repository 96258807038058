import React, { Component, useState } from 'react';

import Webcam from "react-webcam";
import Compressor from 'compressorjs';
import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';

const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET;
const REGION = process.env.REACT_APP_AWS_REGION;
const AWS_URL_BUCKET = process.env.REACT_APP_AWS_URL_BUCKET;

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_KEY_SECRET,
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
});

const videoConstraints = {
  width: 400,
  height: 620,
};

function dataURItoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type:mime});
}

export const WebcamCapture = (props) => {
    const webcamRef = React.useRef(null);
    const [image,setImage]=useState('');
    const [imageBlob,setImageBlob]=useState(null);

    const upload = async () => {
        console.log(imageBlob)
        new Compressor(imageBlob, {
            quality: 1,
            error: (error) => {
                console.log(error)
            },
            success: async (compressedResult) => {   
                const compressed = compressedResult;
                
                const fileName = uuidv4() + '.jpg';
                const params = {
                    ACL: 'public-read',
                    Body: compressed,
                    Key: `posts/${fileName}`,
                    Bucket: S3_BUCKET,
                };
            
                return myBucket.putObject(params)
                    .on('httpUploadProgress', ()=>{})
                    .send(async (err) => {
                        if (err) {
                            console.log(err);
                        }else{
                            const url = [`${AWS_URL_BUCKET}/posts/${fileName}`];
                            props.attach(url);
                            setImage('');
                            setImageBlob(null);
                        }
                    })
            },
        });
    };

  const capture = React.useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      console.log(webcamRef.current)
      setImageBlob(dataURItoBlob(imageSrc))
      setImage(imageSrc);
    },

    [webcamRef]
  );

  return (
    <div className="webcam-container">
      { image==''?
        <div className="text-center">
            <Webcam
                audio={false}
                height={620}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width={400}
                videoConstraints={videoConstraints}
            />
            <br/>
            <button style={{marginRight:10}} onClick={()=>props.callBack()}>Cancelar</button>
            <button onClick={(e)=>{e.preventDefault();capture();}}>Tomar Foto</button>
        </div>
      :
        <div className="text-center">
            <img src={image} style={{marginBottom:25}}/>
            <br/>
            <button style={{marginRight:10}} onClick={()=>props.callBack()}>Cancelar</button>
            <button style={{marginRight:10}} onClick={()=>setImage('')}>Volver a Tomar</button>
            <button onClick={upload}>Añadir</button>
        </div>
      }
    </div>
  );
};