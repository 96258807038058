import React, { Fragment, useEffect, useState } from "react";
import Loader from "./component/common/loader/loader";
import Header from "./component/common/header/header";
import Sidebar from "./component/common/sidebar/sidebar";
import Rightsidebar from "./component/common/sidebar/rightsidebar";
import Footer from "./component/common/footer/footer";
import ThemeCustomize from "./component/common/theme-customizer/themeCustomize";
import { ToastContainer } from "react-toastify";
import { Outlet, useLocation } from "react-router-dom";
import ConfigDB from "./data/customizer/config";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const App = () => {
  const animation = localStorage.getItem("animation") || ConfigDB.data.router_animation || 'fade'
  const location = useLocation();

  const [showBody, setShowBody] = useState(false);
  const url = new URL((typeof document === "undefined") ? 'http://localhost' : document.URL);

  useEffect(() => {
    window.scrollTo(0, 0);
    if(url.pathname==="/pediment"||url.pathname==="/quote"||url.pathname==="/quote-part"||url.pathname==="/bom"||url.pathname==="/purchase"){
      setShowBody(true);
    }
  }, [location.pathname]);


  return (
    <Fragment>
      {/* <Loader /> */}
      {!showBody ?
        <div className="page-wrapper">
          <div className="page-body-wrapper">
            <Header />
            <Sidebar />
            <Rightsidebar />
            <div className="page-body">
              <TransitionGroup>
                <CSSTransition
                  key={location.key}
                  timeout={100}
                  classNames={animation}
                  unmountOnExit
                >
                  <div>
                    <Outlet />
                  </div>
                </CSSTransition>
              </TransitionGroup>
            </div>
            <Footer />
            <ThemeCustomize />
          </div>
        </div>
      :
        <div className="page-wrapper">
            <div className="page-body">
              <TransitionGroup>
                <CSSTransition
                  key={location.key}
                  timeout={100}
                  classNames={animation}
                  unmountOnExit
                >
                  <div>
                    <Outlet />
                  </div>
                </CSSTransition>
              </TransitionGroup>
            </div>
        </div>
      }
      <ToastContainer />
    </Fragment>
  );
};

export default App;
