import React, { useEffect } from "react";
import { MENUITEMS } from "./menu";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CLOSE_SESSION } from "../../../redux/actionType";
import { LogOut } from "react-feather";
import axios from "axios";
import { API_USER } from "../../../redux/apiRoutes";

const Rightsidebar = (props) => {
  const user = useSelector((content) => content.User);
  const history = useNavigate();
  const dispatch = useDispatch();

  var images = require.context("../../../assets/images", true);
  const [mainmenu, setMainMenu] = useState(MENUITEMS);

  const dynamicImage = (image) => {
    return images(`./${image}`);
  };

  const toggleMenu = () => {
    checkRole(user);
    if(document.querySelector(".right-sidebar").classList.contains("show")){
      document.querySelector(".right-sidebar").classList.remove("show");
    }else{
      document.querySelector(".right-sidebar").classList.add("show");
    }
  };

  const logOuts = () => {
    dispatch({
      type: CLOSE_SESSION
    });
    history(`${process.env.PUBLIC_URL}/login`);
  };

  const [roleChecked, setroleChecked] = useState(false);
  const checkRole = async (user) => {  
    await axios.get(`${API_USER}/${user.uuid}`).then(response => {
      if(response.data){
        if(user.role!==response.data.role){
          dispatch({
            type: CLOSE_SESSION
          });
          window.location = `${process.env.PUBLIC_URL}/login`;
        }
      }
    }).catch(e => {
      console.log("No se pudo verificar el rol del usuario");
    });
  }

  useEffect(() => {
    if(user!==null&&!roleChecked){
      checkRole(user);
      setroleChecked(true);
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div className="right-sidebar" id="right_side_bar" style={{marginTop:-40, overflow:'auto', maxHeight:'100%', paddingBottom:40}}>
      <div className="p-4">
        <ul>
          {MENUITEMS.map((menuItem, i) => {
            let hasModulePermissions = false;

            menuItem.permissions.map((permission)=>{
              if(permission===user.role){
                hasModulePermissions=true;
              }
            })

            if(hasModulePermissions){
              return (
                <li className={`${menuItem.active ? "open" : ""}`} key={i} style={{fontSize:'1.2rem'}}>
                  {menuItem.tag}
                  <div
                    className="bar-icons"
                  >
                    {menuItem.icon}
                    <span>{menuItem.title}</span>
                  </div>

                  {menuItem.children ? (
                    <ul className="iconbar-mainmenu custom-scrollbar" style={{padding:10, fontSize:'1.1rem', marginBottom:10}}>
                      {menuItem.children.map((childrenItem, index) => {
                        let hasScreenPermissions = false;

                        if(childrenItem.permissions === false){
                          hasScreenPermissions = true;
                        }
                        
                        if(childrenItem.permissions !== null && childrenItem.permissions !== undefined && childrenItem.permissions !== false){
                          childrenItem.permissions.map((permission)=>{
                              if(permission===user.role){
                                hasScreenPermissions=true;
                              }
                          })
                        }

                        if(hasScreenPermissions){
                          return(
                            <Fragment key={index}>
                              {childrenItem.type === "sub" ? (
                                <li className="iconbar-header">{childrenItem.title}</li>
                              ) : (
                                ""
                              )}
      
                              {childrenItem.type === "sub-header" ? (
                                <li className="iconbar-header sub-header">
                                  {childrenItem.title}
                                </li>
                              ) : (
                                ""
                              )}
      
                              {childrenItem.type === "link" ? (
                                <li className={childrenItem.active ? "active" : ""} style={{paddingBottom:10}}>
                                  <Link
                                    className={childrenItem.active ? "active" : ""}
                                    to={childrenItem.path}
                                    onClick={toggleMenu}
                                  >
                                    {childrenItem.title}
                                  </Link>
                                </li>
                              ) : (
                                ""
                              )}
                            </Fragment>
                          )
                        }
                      })}
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              )
            }
          })}
          <li onClick={logOuts} style={{fontSize:'1.2rem'}}>
            <LogOut /> Salir
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Rightsidebar;
