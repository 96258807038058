import React, { Component, useState } from 'react';

import Compressor from 'compressorjs';
import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';

const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET;
const REGION = process.env.REACT_APP_AWS_REGION;
const AWS_URL_BUCKET = process.env.REACT_APP_AWS_URL_BUCKET;

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_KEY_SECRET,
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
});

export const HydroCapture = (props) => {

    const upload = async (files) => {
        new Compressor(files[0], {
            quality: 1,
            error: (error) => {
                console.log(error)
            },
            success: async (compressedResult) => {   
                const compressed = compressedResult;
                
                const fileName = uuidv4() + '.jpg';
                const params = {
                    ACL: 'public-read',
                    Body: compressed,
                    Key: `posts/${fileName}`,
                    Bucket: S3_BUCKET,
                };
            
                return myBucket.putObject(params)
                    .on('httpUploadProgress', ()=>{})
                    .send(async (err) => {
                        if (err) {
                            console.log(err);
                        }else{
                            const url = [`${AWS_URL_BUCKET}/posts/${fileName}`];
                            props.attach(url);
                        }
                    })
            },
        });
    };

  return (
    <div className="webcam-container">
        <input
            type="file"
            accept='image/jpg, image/jpeg, image/png'
            onChange={(event)=>upload(event.currentTarget.files)}
            />
    </div>
  );
};